import { useMemo } from 'react';

import { Button } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useEmployerOtpExperiment from 'src/components/AccountLogin/hooks/useEmployerOtpExperiment';
import useLoginContext from 'src/components/AccountLogin/hooks/useLoginContext';
import { AuthMethod, LoginSteps } from 'src/components/AccountLogin/types';
import translation from 'src/components/translation';

const TrlKeys = {
    submit: 'mobile.native.login.button.caption.short',
    sendCode: 'login.form.otp.sendCode',
    continue: 'Continue',
};

const Submit: TranslatedComponent = ({ trls }) => {
    const isEmployerOtpExperiment = useEmployerOtpExperiment(false);
    const { submitProps, authMethod, step } = useLoginContext();
    const text = useMemo(() => {
        if (authMethod === AuthMethod.ByCode) {
            if (step === LoginSteps.Login && isEmployerOtpExperiment) {
                return trls[TrlKeys.sendCode];
            }
            return trls[TrlKeys.continue];
        }
        return trls[TrlKeys.submit];
    }, [authMethod, isEmployerOtpExperiment, step, trls]);

    return (
        <Button {...submitProps} mode="primary" style="accent" size="large" stretched type="submit">
            {text}
        </Button>
    );
};

export default translation(Submit);

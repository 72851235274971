import { Fragment } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import Modal from 'src/components/MagritteRedesignComponents/Modal';
import Text from 'src/components/MagritteRedesignComponents/Text';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { SocialNetworks } from 'src/models/LoginForm';

import SocialButton, { SocialButtonProps } from 'src/components/SignInSignUpFlow/SocialButton';

import styles from './social-buttons-modal.less';

interface SocialButtonsModalProps {
    handleCloseModal: () => void;
    isVisible: boolean;
    socialNetworks: SocialNetworks[];
    handleSocialClick?: SocialButtonProps['handleSocialClick'];
}

const TrlKeys = {
    title: 'social.networks.modal.title',
    text: 'social.networks.modal.text',
};

const SocialButtonsModal: TranslatedComponent<SocialButtonsModalProps> = ({
    trls,
    isVisible,
    handleCloseModal,
    socialNetworks,
    handleSocialClick,
}) => {
    const domain = useSelectorNonNullable((state) => state.topLevelDomain);
    const isMagritte = useMagritte();

    return (
        <Modal
            visible={isVisible}
            onClose={handleCloseModal}
            title={trls[TrlKeys.title]}
            size="small"
            content={
                <div className={isMagritte ? '' : styles.wrapper}>
                    <Text
                        blokoComponentProps={{
                            importance: TextImportance.Tertiary,
                        }}
                    >
                        {format(trls[TrlKeys.text], {
                            '{0}': domain,
                        })}
                    </Text>
                    <VSpacing default={32} />
                    {socialNetworks.map((item) => (
                        <Fragment key={item.code}>
                            <SocialButton {...item} handleSocialClick={handleSocialClick} />
                            <VSpacing default={12} />
                        </Fragment>
                    ))}
                </div>
            }
        />
    );
};

export default translation(SocialButtonsModal);

import useExperiment from 'src/hooks/useExperiment';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import useNote from 'src/hooks/useNote';
import { useIsHhru } from 'src/hooks/useSites';

const Notes = {
    employerOtpExperimentForNewUsers: 'employerOtpExperimentPortfolio30467',
    employerOtpExperiment: 'employerOtpExperimentPortfolio32956',
};

export enum OtpExperimentGroup {
    GroupB = 'employer_auth_otp_exp_b',
    GroupC = 'employer_auth_otp_exp_c',
}

const Features = {
    employerOtpEnabled: 'enable_employer_otp',
};

const useEmployerOtpExperiment = (shouldCheckForExperimentGroup = false): OtpExperimentGroup | null => {
    const employerOtpEnabled = useFeatureEnabled(Features.employerOtpEnabled);
    const isHHRu = useIsHhru();
    const [employerOtpExperimentForNewUsersNote] = useNote<boolean>(Notes.employerOtpExperimentForNewUsers);
    const [employerOtpExperimentNote] = useNote<OtpExperimentGroup>(Notes.employerOtpExperiment);
    const checkForExperimentGroup =
        shouldCheckForExperimentGroup && isHHRu && employerOtpEnabled && !employerOtpExperimentNote;
    // Группа B - войти по паролю кнопкой
    const isExperimentEnabledGroupB = useExperiment('employer_auth_otp_exp_b', checkForExperimentGroup);
    // Группа С - войти по паролю ссылкой
    const isExperimentEnabledGroupC = useExperiment('employer_auth_otp_exp_c', checkForExperimentGroup);

    if (!isHHRu || !employerOtpEnabled) {
        return null;
    }

    if (employerOtpExperimentForNewUsersNote) {
        // Пользователи попавшие в предыдущий эксперимент попадут в группу С
        // Для них все остается как было
        return OtpExperimentGroup.GroupC;
    }

    if (employerOtpExperimentNote) {
        return employerOtpExperimentNote;
    }

    if (isExperimentEnabledGroupB) {
        return OtpExperimentGroup.GroupB;
    }

    if (isExperimentEnabledGroupC) {
        return OtpExperimentGroup.GroupC;
    }

    return null;
};

export default useEmployerOtpExperiment;
